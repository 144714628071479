import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
	mode: 'history',
	routes: [
		// 초기 진입 페이지
		{ path: '/', name: 'main', component: () => import('@/views/main/MainPage.vue'), meta: { title: '메인 페이지' } },
		// { path: '/loginmain', name: 'loginmain', component: () => import('@/views/main/LoginMainPage.vue'), meta: { title: '로그인 메인 페이지' } },

		//Login
		{ path: '/mobilelogin', name: 'mobilelogin', component: () => import('@/views/login/MobileLogin.vue'), meta: { title: '모바일 로그인 form' } },
		{ path: '/idpwrecovery', name: 'idpwrecovery', component: () => import('@/views/login/IdPwRecovery.vue'), meta: { title: '아이디/비밀번호 찾기' } },

		// 서비스 신청
		{ path: '/nursery', name: 'nursery', component: () => import('@/views/apply/Nursery.vue'), meta: { title: '어린이집 서비스 신청하기' } },
		{ path: '/kindergarten', name: 'kindergarten', component: () => import('@/views/apply/Kindergarten.vue'), meta: { title: '유치원 서비스 신청하기' } },
		{ path: '/academy', name: 'academy', component: () => import('@/views/apply/Academy.vue'), meta: { title: '학원 서비스 신청하기' } },
		{ path: '/localcare', name: 'localcare', component: () => import('@/views/apply/LocalCare.vue'), meta: { title: '지역돌봄교실 서비스 신청하기' } },
		{ path: '/elementarycare', name: 'elementarycare', component: () => import('@/views/apply/ElementaryCare.vue'), meta: { title: '초등학교 돌봄교실 서비스 신청하기' } },
		{ path: '/elementary', name: 'elementary', component: () => import('@/views/apply/Elementary.vue'), meta: { title: '초등학교 서비스 신청하기' } },
		// { path: '/apply', name: 'apply', component: () => import('@/views/apply/Apply.vue'), meta: { title: '기존' } },

		// Board
		{ path: '/noticeboard', name: 'noticeboard', component: () => import('@/views/board/NoticeBoard.vue'), meta: { title: '공지사항' } },
		{ path: '/companynews', name: 'companynews', component: () => import('@/views/board/CompanyNews.vue'), meta: { title: '보도자료' } },
		{ path: '/faqboard', name: 'faqboard', component: () => import('@/views/board/FaqBoard.vue'), meta: { title: '자주하는 질문' } },
		{ path: '/serviceterms', name: 'serviceterms', component: () => import('@/views/board/ServiceTerms.vue'), meta: { title: '이용약관' } },
		{ path: '/privacypolicy', name: 'privacypolicy', component: () => import('@/views/board/PrivacyPolicy.vue'), meta: { title: '개인정보처리방침' } },
		{ path: '/companyfriends', name: 'companyfriends', component: () => import('@/views/board/CompanyFriends.vue'), meta: { title: '아이알리미 프렌즈' } },
		{ path: '/ialimeemanual', name: 'ialimeemanual', component: () => import('@/views/board/IAlimeeManual.vue'), meta: { title: '이용가이드' } },

		// 없는 페이지
		{ path: '*', component: () => import('@/components/common/NotFoundPage.vue'), meta: { title: 'Page is not found' } },
	],
	scrollBehavior(to, from, savedPosition) {
		if (to.hash) {
			/** firefox에서 스크롤 이동 시, 1000ms 후 hash 초기화되면서 다시 top으로 이동되는 이슈때문에 제거해봄
			setTimeout(() => {
				window.location.hash = '';
			}, 1000);
			*/

			if (window.innerWidth < 1260) {
				return {
					selector: to.hash,
					offset: { y: 56 },
					behavior: 'smooth',
				};
			} else {
				return {
					selector: to.hash,
					offset: { y: 152 },
					behavior: 'smooth',
				};
			}
		}
		if (savedPosition) {
			return savedPosition;
		}
		return { x: 0, y: 0 };
	},
});

export default router;
