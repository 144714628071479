<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
import { defualtMeta } from '@/utils/meta';

export default {
	metaInfo: {
		title: '아이알리미 | 행복을 이어주는 안심알리미',
		meta: defualtMeta,
	},
	name: 'App',
};
</script>

<style>
@import './assets/css/common.css';

:root {
	--primary-color: #ff7f46;
	--secondary-color: #2f7ce0;
	--highlight-color: #ebff00;
	--dark-black-color: #111;
	--light-black-color: #333; /**아직 사용되지 않음 */
	--dark-grey-color: #535353;
	--light-grey-color: #939393;
}

* {
	font-family: 'Pretendard';
}

body {
	color: var(--dark-black-color);
	word-break: keep-all;
}

video {
	-webkit-mask-image: -webkit-radial-gradient(white, black);
	mask-image: -webkit-radial-gradient(white, black);
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
}
</style>
