import Vue from 'vue';
import App from './App.vue';
import Meta from 'vue-meta';
import router from '@/router/index';
import { formatDate1 } from '@/utils/filters';
import { formatDate2 } from '@/utils/filters';
import { formatDate3 } from '@/utils/filters';

Vue.filter('formatDate1', formatDate1);
Vue.filter('formatDate2', formatDate2);
Vue.filter('formatDate3', formatDate3);
Vue.use(Meta);

new Vue({
	render: h => h(App),
	router,
}).$mount('#app');
